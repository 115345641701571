import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _09d2055e = () => interopDefault(import('../pages/check-email.vue' /* webpackChunkName: "pages/check-email" */))
const _2b8fe720 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _ac4aebda = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _75818be4 = () => interopDefault(import('../pages/medications/index.vue' /* webpackChunkName: "pages/medications/index" */))
const _00b8d752 = () => interopDefault(import('../pages/password-updated.vue' /* webpackChunkName: "pages/password-updated" */))
const _0b4eb846 = () => interopDefault(import('../pages/paylinks/index.vue' /* webpackChunkName: "pages/paylinks/index" */))
const _04ca57ba = () => interopDefault(import('../pages/prescriptions/index.vue' /* webpackChunkName: "pages/prescriptions/index" */))
const _0ec0766c = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _858d12ea = () => interopDefault(import('../pages/registered.vue' /* webpackChunkName: "pages/registered" */))
const _16da2c4e = () => interopDefault(import('../pages/resend-confirmation.vue' /* webpackChunkName: "pages/resend-confirmation" */))
const _e91bc53c = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _d7377828 = () => interopDefault(import('../pages/settings.vue' /* webpackChunkName: "pages/settings" */))
const _5a056816 = () => interopDefault(import('../pages/settings/close-account.vue' /* webpackChunkName: "pages/settings/close-account" */))
const _6dc3a5e7 = () => interopDefault(import('../pages/settings/export.vue' /* webpackChunkName: "pages/settings/export" */))
const _bb9c9bce = () => interopDefault(import('../pages/settings/hospitals/index.vue' /* webpackChunkName: "pages/settings/hospitals/index" */))
const _9449d80a = () => interopDefault(import('../pages/settings/options.vue' /* webpackChunkName: "pages/settings/options" */))
const _02956a78 = () => interopDefault(import('../pages/settings/pharmacies.vue' /* webpackChunkName: "pages/settings/pharmacies" */))
const _a4af2e9a = () => interopDefault(import('../pages/settings/security.vue' /* webpackChunkName: "pages/settings/security" */))
const _3a79453e = () => interopDefault(import('../pages/settings/user.vue' /* webpackChunkName: "pages/settings/user" */))
const _0a3d3ba7 = () => interopDefault(import('../pages/settings/hospitals/new.vue' /* webpackChunkName: "pages/settings/hospitals/new" */))
const _66421884 = () => interopDefault(import('../pages/settings/hospitals/_id/index.vue' /* webpackChunkName: "pages/settings/hospitals/_id/index" */))
const _d45a2b70 = () => interopDefault(import('../pages/settings/hospitals/_id/edit.vue' /* webpackChunkName: "pages/settings/hospitals/_id/edit" */))
const _0cb8aef6 = () => interopDefault(import('../pages/embed/rx.vue' /* webpackChunkName: "pages/embed/rx" */))
const _0cbb5831 = () => interopDefault(import('../pages/prescriptions/new.vue' /* webpackChunkName: "pages/prescriptions/new" */))
const _997bf994 = () => interopDefault(import('../pages/redirect/new.vue' /* webpackChunkName: "pages/redirect/new" */))
const _20906959 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _0ebbfad6 = () => interopDefault(import('../pages/paylinks/_slug.vue' /* webpackChunkName: "pages/paylinks/_slug" */))
const _3a7f0990 = () => interopDefault(import('../pages/prescriptions/_signature.vue' /* webpackChunkName: "pages/prescriptions/_signature" */))
const _4b3f46cc = () => interopDefault(import('../pages/new-password/_uid/_token.vue' /* webpackChunkName: "pages/new-password/_uid/_token" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/check-email",
    component: _09d2055e,
    name: "check-email"
  }, {
    path: "/login",
    component: _2b8fe720,
    name: "login"
  }, {
    path: "/logout",
    component: _ac4aebda,
    name: "logout"
  }, {
    path: "/medications",
    component: _75818be4,
    name: "medications"
  }, {
    path: "/password-updated",
    component: _00b8d752,
    name: "password-updated"
  }, {
    path: "/paylinks",
    component: _0b4eb846,
    name: "paylinks"
  }, {
    path: "/prescriptions",
    component: _04ca57ba,
    name: "prescriptions"
  }, {
    path: "/register",
    component: _0ec0766c,
    name: "register"
  }, {
    path: "/registered",
    component: _858d12ea,
    name: "registered"
  }, {
    path: "/resend-confirmation",
    component: _16da2c4e,
    name: "resend-confirmation"
  }, {
    path: "/reset-password",
    component: _e91bc53c,
    name: "reset-password"
  }, {
    path: "/settings",
    component: _d7377828,
    name: "settings",
    children: [{
      path: "close-account",
      component: _5a056816,
      name: "settings-close-account"
    }, {
      path: "export",
      component: _6dc3a5e7,
      name: "settings-export"
    }, {
      path: "hospitals",
      component: _bb9c9bce,
      name: "settings-hospitals"
    }, {
      path: "options",
      component: _9449d80a,
      name: "settings-options"
    }, {
      path: "pharmacies",
      component: _02956a78,
      name: "settings-pharmacies"
    }, {
      path: "security",
      component: _a4af2e9a,
      name: "settings-security"
    }, {
      path: "user",
      component: _3a79453e,
      name: "settings-user"
    }, {
      path: "hospitals/new",
      component: _0a3d3ba7,
      name: "settings-hospitals-new"
    }, {
      path: "hospitals/:id",
      component: _66421884,
      name: "settings-hospitals-id"
    }, {
      path: "hospitals/:id/edit",
      component: _d45a2b70,
      name: "settings-hospitals-id-edit"
    }]
  }, {
    path: "/embed/rx",
    component: _0cb8aef6,
    name: "embed-rx"
  }, {
    path: "/prescriptions/new",
    component: _0cbb5831,
    name: "prescriptions-new"
  }, {
    path: "/redirect/new",
    component: _997bf994,
    name: "redirect-new"
  }, {
    path: "/",
    component: _20906959,
    name: "index"
  }, {
    path: "/paylinks/:slug",
    component: _0ebbfad6,
    name: "paylinks-slug"
  }, {
    path: "/prescriptions/:signature",
    component: _3a7f0990,
    name: "prescriptions-signature"
  }, {
    path: "/new-password/:uid?/:token?",
    component: _4b3f46cc,
    name: "new-password-uid-token"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
